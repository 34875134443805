export let isIE9 = false;
export let isIE10 = false;
export let isIE11 = false;
let ua = window.navigator.userAgent;

if(navigator.appVersion.indexOf("MSIE 9.") !== -1) {
    document.documentElement.className += 'ie9';
    isIE9 = true;
} else if (ua.indexOf("Trident/7.0") > -1) {
    document.documentElement.className += 'ie11';
    isIE11 = true;
} else if (ua.indexOf("Trident/6.0") > -1) {
    document.documentElement.className += 'ie10';
    isIE10 = true;
}
if ((isIE9 || isIE10 || isIE11) && !Array.prototype.includes) {
    Object.defineProperty(Array.prototype, "includes", {
        enumerable: false,
        value: function(obj) {
            let newArr = this.filter(function(el) {
                return el == obj;
            });
            return newArr.length > 0;
        }
    });
}