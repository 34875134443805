import { clearMap } from '../modules/map';
import { read_local_storage_item } from './localStorage';
import { globalBodyPopup } from './global_popup';
import { addFavoriteToCookie, delFavoriteFromCookie } from './favorites_cookies';
import { modelDisplaySearchScheduleResults } from 'schedule_bundle/module/bookmarks';
import { goBackToBoard } from '../structure/home';
import {initializeBoard} from "../structure";
import { displaySelectedSection } from '../structure';
export var checkFavoritesOnPage, clickOnFavorite, manageFavorites, enableFavorites;


/**
 * NB : this file is include two time inside the header, i have to make sur to load juste one time the App.attachEvents.
 * todo : check map_result.js ou include it too
 */

/**
 * Manage favorite UX / UI
 */
var manageFavorites = function () { 
    // permanent event
    JKisio(document).on('click', '#footer_favorites', function(event) {
        event.preventDefault();
        showBookmarkWrapper();
    });

    // permanent event
    JKisio(document).on('click', '#favorites_clear', function (event) {
        event.preventDefault();
        
        let section = typeof Kisio.sectionOrigin !== "undefined" ? 
            Kisio.sectionOrigin
            : document.body.querySelector("#board_container").getAttribute('data-section');

        JKisio("#board_container").removeClass("board_open_bookmark");
        document.dispatchEvent(new CustomEvent('clear_map', {detail: {clean_data:false}}));
        hideProxFavContent(JKisio("#favorites_content"), 'favorite');
        
        if (section !== null) {
            if (section == "proximity") {
                document.body.dispatchEvent(new CustomEvent('open_proximity_event'));
                return;
            }
            displaySelectedSection(section);
            return;
        }

        goBackToBoard();
    });

    document.body.addEventListener('displayBookmark', function(event) {
        event.preventDefault();
        document.body.dispatchEvent(new CustomEvent('hide_proximity_bundle'));
        showBookmarkWrapper();
    })
};

/**
 * Prepare to show boomark wrapper
 */
export function showBookmarkWrapper()
{
    // hide footer main block
    JKisio('#board_footer').hide();
    document.dispatchEvent(new CustomEvent('clear_map', {detail: {clean_data:false}}));
    // hide other context
    JKisio('#board_title_container, #journey_content, #schedule_content, #ctp-proximity').hide();
    JKisio("#board_container").addClass("board_open");
    JKisio("#board_container").addClass("board_open_bookmark");
    JKisio("#board").addClass("expanded");
    // get meanimal wrapper
    if(JKisio("#ctp-favorites").length === 0) {
        JKisio("#board_content").append(
            '<div id="favorites_content" style="display: block;">\n' +
            '   <div id="title_container" class="clearfix">\n' +
            '      <div id="favorites_title" class="board_title selected" tabindex="0">\n' +
            `         ${Translator.trans("bookmark.title")}\n` +
            '      </div>\n' +
            '      <span id="favorites_clear" role="button" class="ikisio ikisio-back" tabindex="0" aria-label="Fermer l\'onglet Mes Favoris"></span>\n' +
            '      <i class="ikisio ikisio-favorites"></i>\n' +
            '   </div>\n' + // end title_container
            '   <div class="favorites-full" id="ctp-favorites" style="height: auto; overflow: hidden">\n' +
            '   </div>\n' + // end favorites-full
            '</div>'
        );
    }
    JKisio("#ctp-favorites").css('display', 'block');
    JKisio("#favorites_content").css('display', 'block');

    // heare you can modifiy ckass of board_content..
    JKisio('#board_title_container, #journey_content, #schedule_content, #ctp-proximity').hide();

    document.body.dispatchEvent(new CustomEvent(`bookmark_container_displayed`));
}

var clickOnFavorite = () => {
    // throw new Error("Depracted please use element builder instead");
};

var enableFavorites = function (type) {
    let display_favorites = false;
    let favorites_manager, favManager;
    if (Kisio.enable_favorites) {
        // We enable the favorites only if this particular type is authorized te be used
        if (Kisio.favorites_types.indexOf(type) > -1) {
            // We enable the favorites only if one Manager is enabled also
            favManager = "auto";
            display_favorites = true;
            favorites_manager = auto;
        }
    }
    return [display_favorites, favorites_manager];
};

export var hideProxFavContent = function (div, type, callback) {
    let height = JKisio(div).height();
    if (type === 'proximity') {
        JKisio('#ctp-proximityForm .autocomplete-search-icon, #ctp-proximityForm .locate-position').hide();
    }

    JKisio(div).hide();
    if (type === 'proximity') {
        JKisio('#proximity_content').html('');
    }
    JKisio('#board_title_container, #board_footer').show();
    if (JKisio('#schedule_title').hasClass('selected')) {
        JKisio('#schedule_content').show();
    }else{
        if (JKisio('#result_content').length > 0 && !JKisio('#result_content').is(':visible')) {
            JKisio('#result_content').show();
        }
    }
    if (type === 'favorite') {
        JKisio('#favorites_content').remove();
    }
    if (typeof callback === "function") {
        callback();
    }
};

var checkFavoritesOnPage = () => {
    // todo check by id in cache
};