import { parameters } from "../../../plugnplay/leaf-core";
import { manageGeoMarkers } from "../../map-popup";

/**
 * Function to add marker after geolocation
 *
 * @param e
 * @constructor
 */
export function locationFound(e) {
    if (!e.latlng) {
        return;
    }

    const coords = e.latlng.lng+';'+e.latlng.lat;
    const inverted_coords = e.latlng.lat+';'+e.latlng.lng;
    
    let fieldType = JKisio(this).attr('data-type');
    
    if (fieldType !== undefined) {
        manageGeoMarkers(fieldType, '', e.latlng);

        JKisio(`#search_${fieldType}_autocomplete`).val(Translator.trans('journey.form.geolocalisation.field_value'));
        JKisio(`#search_${fieldType}_autocomplete-hidden`).val(coords);
     
    }else {
        const icon = L.icon({
            iconUrl: parameters.urlImages + 'map-marker-from.svg',
            iconSize: parameters.icon_size
        });
        Kisio.layerGroup.home.homeLayers.addLayer(L.marker(e.latlng, {icon: icon, keyboard: false}));
        Kisio.layerGroup.home.homeLayers.addTo(window.leaflet.map);
    }

    if (JKisio('#proximity_search_uri').length > 0) {
        JKisio('#proximity_search_uri_autocomplete').val(Translator.trans('journey.form.geolocalisation.field_value'));
        JKisio('#proximity_search_uri_autocomplete-hidden').val(coords);
        let new_url = "/fr/full/proximity/result/?proximity_search%5Buri%5D%5Bautocomplete-hidden%5D="+coords+"&proximity_search%5Buri%5D%5Bautocomplete%5D="+Translator.trans('journey.form.geolocalisation.field_value')+"&proximity_search%5Bcoords%5D="+inverted_coords;
        window.location.href = new_url;
    }

    window.leaflet.map.off('locationfound', locationFound, this);
};
