const params = {
    fromId: '#search_from_autocomplete',
    fromIdHidden: '#search_from_autocomplete-hidden',
    toId: '#search_to_autocomplete',
    toIdHidden: '#search_to_autocomplete-hidden',
};

export function initAutocompleteInputs() {
    window.autocomplete = {
        from: JKisio(params.fromId).val(),
        to: JKisio(params.toId).val()
    };

    window.autocompleteHidden = {
        from: JKisio(params.fromIdHidden).val(),
        to: JKisio(params.toIdHidden).val()
    };
}
