export var modelDisplaySearchScheduleResults = Backbone.Model.extend({
    params: {
        scheduleDivId: '#schedule_stop_area_lines',
        containerId: '#ctp-linesSearchResultsContainer',
        ulScheduleId: '#ctp-searchScheduleResults',
        divScheduleId: '#ctp-searchSchedule',
        autocompleteId: '#schedule_stop_area_lines_autocomplete',
        autocompleteClear: '#schedule-stop-area-lines-autocomplete-clear',
        boardContainerId: '#board_container',
        stopScheduleBloc: '.kisio-stop-schedule',
        disruptionsArray: []
    },

    /**
     * Function to fetch Next Departures for Times Favorites on Favorites Page
     * @param item
     * @param stop_area_id
     * @param line_id
     * @param network_id
     */
    getScheduleTimesForFavorites: function (item, stop_area_id, line_id, network_id, direction_type) {
        let query = {
            type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
            stop_area_id: stop_area_id,
            line_id: line_id,
            network_id: network_id,
            from_datetime: 'now',
            data_freshness: 'realtime'
        };

        JKisio.ajax({
            url: Routing.generate('schedule_next_time', query),
            success: function (data) {
                let htmlLine = '';
                let imgPicto = '';
                if (data.line_informations.commercial_mode !== undefined) {
                    imgPicto = getModePicto(data.line_informations.commercial_mode);
                }
                htmlLine += imgPicto;
                let spanLineCode = '';
                if (data.line_informations.color !== undefined && data.line_informations.text_color !== undefined && data.line_informations.code !== undefined) {
                    spanLineCode = '<span class="line-code" style="background-color: #' + data.line_informations.color + '; color: #' + data.line_informations.text_color + '">' + data.line_informations.code + '</span>'
                } else {
                    spanLineCode = '<span class="line-code">—</span>';
                }
                htmlLine += spanLineCode;
                let schedule_direction_type = [];
                for (let direction in data.direction_type) {
                    let key = data.direction_type[direction];
                    let value = direction;
                    schedule_direction_type[key] = value;
                }
                let direction_name = '';
                if (schedule_direction_type[direction_type] !== undefined) {
                    direction_name = schedule_direction_type[direction_type];
                }
                let time = '';
                if (data.schedule[direction_name] !== undefined) {
                    let htmlTimes = data.schedule[direction_name];
                    time = htmlTimes[0];
                    if (time === 'no_departure_this_day') {
                        time = Translator.trans('stop_schedules.' + time);
                    }
                    if (time === 'active_disruption') {
                        time = Translator.trans('schedule.result.disruptions.multiple');
                    }
                }
                let directionHtml = '';
                if (direction_name !== '') {
                    directionHtml = Translator.trans('journey.section.street_network.walking.title') + ' ' + direction_name;
                }
                JKisio(item).find('.line-name').html('').html(htmlLine);
                JKisio(item).find('.times-container .line-direction').html('').html(directionHtml);
                JKisio(item).find('.times-container .times').html('').html(time);
            },
            error: function (data) {
                console.log(data);
            }
        });
    },
});

function getModePicto(commercial_mode) {
    let mode = commercial_mode.toLowerCase();
    let img = window.Kisio.modes_img[mode];
    if (typeof window.Kisio.modes_img[mode] === 'undefined') {
        img = window.Kisio.modes_img['commercial_mode:'+mode];
    }
    return '<img src="' + img + '" class="icon-mode" alt="" />';
};
