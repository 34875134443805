export function reverseMarker() {
    if (window?.leaflet?.markers?.from && window?.leaflet?.markers?.to){
        const toLatLng = window.leaflet.markers.from._latlng;
        const fromLatLng = window.leaflet.markers.to._latlng;

        window.leaflet.map.removeLayer(window.leaflet.markers.from);
        window.leaflet.map.removeLayer(window.leaflet.markers.to);

        window.leaflet.markers.to = window.Kisio.MapPopup.getCustomerMarker('to', toLatLng);
        window.leaflet.markers.from = window.Kisio.MapPopup.getCustomerMarker('from', fromLatLng);
        window.leaflet.markers.to.addTo(window.leaflet.map);
        window.leaflet.markers.from.addTo(window.leaflet.map);

    } else if (window?.leaflet?.markers?.from) {
        const toLatLng = window.leaflet.markers.from._latlng;

        window.leaflet.map.removeLayer(window.leaflet.markers.from);

        if (!window?.leaflet?.markers?.to){
            window.leaflet.markers.from = '';
            window.leaflet.markers.to = window.Kisio.MapPopup.getCustomerMarker('to', toLatLng);
            window.leaflet.markers.to.addTo(window.leaflet.map);
        }
    } else if (window?.leaflet?.markers?.to) {
        const fromLatLng = window.leaflet.markers.to._latlng;

        window.leaflet.map.removeLayer(window.leaflet.markers.to);

        if (!window?.leaflet?.markers?.from){
            window.leaflet.markers.to = '';
            window.leaflet.markers.from = window.Kisio.MapPopup.getCustomerMarker('from', fromLatLng);
            window.leaflet.markers.from.addTo(window.leaflet.map);
        }
    }
};