import { params } from './constants';
import {goBackToBoard, openTitle} from "./home";
import {clearMarker, reverseMarker} from "../modules/map";
import {datePicker, initHeaderDateTime} from "../plugnplay/datepicker";
import {timePicker} from "../lib/timepicker";
import {manageShowLines} from "../modules/map/lines";
import {executeIfMapExist} from "pnp_core_bundle/modules/map/function";

/** @TODO remove dependencies **/
export * from './constants';

export function initializeBoard() {
    displayTargetSection();
    Kisio.loadBoardEvents.models.libModels = Backbone.Model.extend({
        /**
         * this function allows to display content selected
         * @param elementId
         */
        showContentSelected: function () {
            JKisio('.board_title').each(function() {
                if(JKisio(this).hasClass('selected')) {
                    openTitle();
                }
            });
        },

        /**
         * this function display content selected
         */
        manageDisplayBoardContent: function (params, widthBody) {
            self = this;
            // let container = JKisio('#board_title_container');
            let boardContentElements = JKisio(params.boardContentId).children();
            let boardTitleElements = JKisio(params.boardTitleId);
            if (boardContentElements.length > 0 && boardTitleElements.length > 0){
                // Manage go back to board initial state
                const backIcon = JKisio('#board_title_container .ikisio-back');
                backIcon?.click(function () {
                    goBackToBoard();
                });

                document.body.addEventListener('go_to_board', function() {
                    goBackToBoard();
                });

                JKisio('#journey_title')?.click(function (e) {
                    displaySelectedSection('journey', e.currentTarget);
                });

                JKisio('#schedule_title')?.click(function (e) {
                    displaySelectedSection('schedule', e.currentTarget);
                });

                JKisio('#traffic_title')?.click(function (e) {
                    document.dispatchEvent(new CustomEvent('clear_map', {detail: {clean_data:false}}));
                    executeIfMapExist(() => {window.leaflet.map.setZoom(Kisio.map_config.zoom);});
                    displaySelectedSection('traffic', e.currentTarget);
                });

                // Event listen to click of board_title
                JKisio('#board_title_container .board_title')?.on('keyup', (e) => {
                    if (['Enter', 'NumpadEnter'].includes(e.key)) {
                        e.target.click();
                    } 
                })

                // Event Listener for display section
                document.body.addEventListener('display_selected_section', (e) => {
                    displaySelectedSection(e.detail)
                    Kisio.section_active = e.detail;
                })
            }
        },

        /**
         * function to set layers style
         * @param type
         * @param style
         */
        setStyleLayer: function(type, style) {
            executeIfMapExist(() => {
                if (typeof type !== 'undefined' && typeof style === 'object' && JKisio('#mapid').is(':visible')) {
                    window.leaflet.map.eachLayer(function(layer){
                        let el = layer.options.layerType;
                        if (typeof el !== 'undefined') {
                            let name = el.split('-');
                            if (el !== type && el !== (type + '-marker') ) {
                                if (Array.isArray(name) && name.includes('marker')) {
                                    layer.setOpacity(0);
                                } else {
                                    layer.setStyle({opacity: '0', fillOpacity: '0'})
                                }
                            } else if (el === type) {
                                layer.setStyle(style);
                            } else if (Array.isArray(name) && name.includes('marker')) {
                                layer.setOpacity(1);
                            }
                        }
                    });
                }
            });
        },

        /**
         * this function manage display or non of the blocs setting/now
         */
        manageSettingNowBlocs: function (widthBody, params, id) {

            /**
             *
             * @param id
             * @param event
             */
            let showHideContentById = function (event, id) {
                if (JKisio(id).length > 0) {
                    if (event === 'show') {
                        JKisio(id).show();
                    }
                    if (event === 'hide')
                        JKisio(id).hide();
                }
            };
            // Accessibility compliance
            JKisio([params.accordionHeaderClass, params.choiceModeLabelClass, params.choiceDrawdownModeLabelClass, params.choiceTravelerType, params.choiceProfileLevel].join(' ,')).on('keyup', function (e) {
                if (['Enter', 'NumpadEnter'].includes(event.key)) {
                    JKisio(this).trigger('click');
                }
            });

            JKisio('.search_datetime_represents').on('keyup', function (e) {
                let target = e.target;
                if (['Enter', 'NumpadEnter'].includes(event.key)) {
                    JKisio(target).find('label').trigger('click');
                }
            });

            // quick fix double import on webpack : remove this when fix 
            JKisio(document).off('click', params.accordionHeaderClass).on('click', params.accordionHeaderClass, function(e) {
                e.preventDefault();
                let $this = JKisio(this);
                let $other =JKisio(params.accordionHeaderClass).not($this);
                
                if($other.hasClass('show')) {
                    setElementStatus($other, 'default', 'true', 'hide', params.classContent);
                }

                if ($this.hasClass('show')){
                    $this.attr('aria-expanded', false);
                    setElementStatus($this, 'default', 'true', 'hide', params.classContent);
                    if ( JKisio(params.formNewSearchId).is(":visible")) {
                        JKisio(params.classContent).hide();
                    }
                } else if ($this.next().hasClass('show')){
                    $this.attr('aria-expanded', true);
                    $this.next().attr('aria-expanded', false);
                    setElementStatus($this, 'next', 'false', 'show', params.classContent);
                } else if ($this.prev().hasClass('show')){
                    $this.attr('aria-expanded', true);
                    $this.prev().attr('aria-expanded', false);
                    setElementStatus($this, 'prev', 'false', 'show', params.classContent);
                } else {
                    $this.attr('aria-expanded', true);
                    setElementStatus($this, 'default', 'true', 'show', params.classContent);
                    if ( !JKisio(params.formNewSearchId).is(":visible")) {
                        let bg = JKisio(params.formModifyId+' button').css('background-color');
                        localStorage.setItem('bgFormModifyId', bg);
                        JKisio(params.formModifyId).show();
                    }
                }
                if (this.classList.contains('show')) {
                    JKisio('#journey-target-form, #board_content').css({'overflow-y': 'auto'});
                } else {
                    JKisio('#journey-target-form, #board_content').css({'overflow-y': ''});
                }
            });

            /**
             * this function find element in dom
             * @param e
             * @param index
             * @param someElement
             * @param type
             * @param id
             */
            var setElementStatus = function (e, index, someElement, type, id) {
                let _this = '';
                switch (index) {
                    case 'next':
                        _this = e.next();
                        break;
                    case 'prev':
                        _this = e.prev();
                        break;
                    default:
                        _this = e;
                }
                let indexOfElement = _this.index();
                _this.removeClass(params.activeFileds);
                _this.find(params.arrowClass).removeClass(params.iconDown).addClass(params.iconRight);
                if (someElement !== 'true')
                    e.parent().parent().find(params.journeySearchContent)
                        .eq(indexOfElement).removeClass('show').slideUp('250');
                if (type === 'hide') {
                    e.parent().parent().find(params.journeySearchContent)
                        .eq(e.index()).removeClass('show').slideUp('250', function () {
                    });
                    showHideContentById('show', id);
                }
                if (type === 'show') {
                    e.toggleClass(params.activeFileds);
                    e.find(params.arrowClass).removeClass(params.iconRight).addClass(params.iconDown);
                    e.parent().parent().find(params.journeySearchContent)
                        .eq(e.index()).toggleClass('show')
                        .slideDown('200', function () {
                            if (JKisio(this).is('#ui-accordion-content-settings')) {
                                JKisio(this).children(".simplebar-content div:first-child").focus();
                            } else if (JKisio(this).is('.ui-accordion-content-now')) {
                                JKisio(this).children(":first-child").focus();
                            }
                        });
                    showHideContentById('hide', id);
                }
            };

        },

        /**
         * Display clearable button and hide placeholder when focus input
         * @param params
         */
        clearable: function (params) {
            let placeholderJourneySearchForm;
            JKisio(params.fromId + ', ' + params.toId + ', ' + params.scheduleInputId).bind("keyup change",function (e) {
                if (JKisio('#' + e.currentTarget.id).val()){
                    // remove error message when inputs autocomplete are not empty
                    if (JKisio('#' + e.currentTarget.id).parent().hasClass(params.errorAttr)){
                        JKisio('#' + e.currentTarget.id).parent().removeClass(params.errorAttr);
                        JKisio('.popup.show').remove();
                        JKisio('#' + e.currentTarget.id).get(0).setCustomValidity("");
                    }
                    let formId = JKisio(this).parents('form:first').attr('id');
                    if (formId !== 'ctp-precisionForm') JKisio('#' + e.currentTarget.id.replace(/_/gi, '-') + '-clear').show();
                }
                let currentElement = e.currentTarget.id;
                let autocompleteValue = JKisio(this).val();

                if (!autocompleteValue){
                    JKisio('#' + e.currentTarget.id.replace(/_/gi, '-') + '-clear').hide();
                } else {
                    if (e.currentTarget.id === params.fromAttr){
                        window.autocomplete.from = autocompleteValue;
                    } else if (e.currentTarget.id === params.toAttr){
                        window.autocomplete.to = autocompleteValue;
                    }
                    JKisio('#' + e.currentTarget.id.replace(/_/gi, '-') + '-clear').on('keyup', function (evt) {
                        if (evt.keyCode == 32 || evt.keyCode == 13) {
                            JKisio(this).trigger('click');
                        }
                    });
                    JKisio('#' + e.currentTarget.id.replace(/_/gi, '-') + '-clear').click(function () {
                        JKisio('#' + currentElement).val('');
                        JKisio('#' + currentElement + '-hidden').val('');
                        JKisio('#' + e.currentTarget.id.replace(/_/gi, '-') + '-clear').hide();
                        if ([params.toId, params.fromId].includes('#' + currentElement)) {
                            window.autocomplete.to = JKisio(params.toId).val();
                            window.autocomplete.from = JKisio(params.fromId).val();
                            clearMarker('#' +currentElement);
                        }
                    })
                }
            }).focusin(function () {
                placeholderJourneySearchForm = JKisio(this).attr('placeholder');
                JKisio(this).attr('placeholder', '');
                window.autocomplete.to = JKisio(params.toId).val();
                window.autocomplete.from = JKisio(params.fromId).val();
            }).focusout(function () {
                JKisio(this).attr('placeholder', placeholderJourneySearchForm);
                window.autocomplete.to = JKisio(params.toId).val();
                window.autocomplete.from = JKisio(params.fromId).val();
            });

            this.reverse(params)
        },

        /**
         * this function reverse value of inputs
         * @param params
         */
        reverse: function (params) {
            JKisio(params.reverseButtonId).click(function (e) {
                initAutocompleteInputs();

                let inputFromId = params.fromId.replace(/_/g, '-') + '-clear';
                let inputToId = params.toId.replace(/_/g, '-') + '-clear';
                if (e) {
                    e.preventDefault();
                }

                const fromAutocomplete = window.autocomplete.from;
                const toAutocomplete = window.autocomplete.to;
                const fromAutocompleteHidden = window.autocompleteHidden.from;
                const toAutocompleteHidden = window.autocompleteHidden.to;

                JKisio(params.fromId).val(toAutocomplete).change();
                window.autocomplete.from = toAutocomplete;

                JKisio(params.toId).val(fromAutocomplete).change();
                window.autocomplete.to = fromAutocomplete;

                JKisio(params.fromIdHidden).val(toAutocompleteHidden);
                window.autocompleteHidden.from = toAutocompleteHidden;

                JKisio(params.toIdHidden).val(fromAutocompleteHidden);
                window.autocompleteHidden.to = fromAutocompleteHidden;

                if (!JKisio(params.fromId).val()){
                    JKisio(inputFromId).hide();
                } else {
                    JKisio(inputFromId).show();
                }
                if (!JKisio(params.toId).val()){
                    JKisio(inputToId).hide();
                } else {
                    JKisio(inputToId).show();
                }

                reverseMarker();

                //Detect if we are on result page, if yes user can reverse and relaunch the search
                if (JKisio('.kisio-result').length > 0) {
                    JKisio('#journey-target-form').submit();
                }
            });
        },

        /**
         * Manage change in departure/arrival
         */
        manageInputChange: function (params, widthBody) {
            JKisio(params.fromId+','+ params.toId).on("change, click, keyup", function (e) {
                if (JKisio(params.classContent).length > 0 && e.keyCode !== 9) {
                    JKisio(params.classContent).hide();
                    JKisio('#journey_content').addClass('open_search');
                    JKisio(params.formNewSearchId).show();
                    JKisio(params.searchBoardModes).show();
                    JKisio('#kisio-result-col').addClass('search-mode');
                }
            });
        },

    });
    const modelLoadBoard = Kisio.loadBoardEvents.models.libModels.extend({
        init: function (params) {
            if (window.Kisio.type_product === 'standalone') {
                JKisio('#board_title_container .ikisio-back').hide();
                JKisio('#board').addClass(window.Kisio.type_product);
            }
            let widthBody = this.getWidthOfElement('body');
            this.showContentSelected();
            this.clearable(params);
            this.manageDisplayBoardContent(params, widthBody);

            if (JKisio(params.boardContentId).length > 0) {
                // datepicker initialize
                datePicker();
                timePicker('timepicker');

                // Display datetime info
                let headerDate = new initHeaderDateTime();
                headerDate.initialize();
            }

            /*
             * Display or not the bloc "Maintenant/Préférences"
             */
            this.manageSettingNowBlocs(
                widthBody,
                params
            );

            this.manageInputChange(params, widthBody);
        },

        /**
         * @param id
         * @returns {*}
         */
        getWidthOfElement: function (id) {
            return JKisio(id).outerWidth(true);
        }
    });

    const boardContainer = new modelLoadBoard();
    boardContainer.init(params);
}

export function displaySelectedSection(sectionName, el = null) {
    el = el ?? document.querySelector(`#${sectionName}_title`);
    //hide all contents
    hideAllContents();

    if (el !== null) {
        // display current content
        el.classList.add('selected');
        JKisio('#board_title_container .ikisio-back').removeClass('arrow-back-white');
        openTitle();
        JKisio(`#${sectionName}_content`)?.show();
    }

    // add sectionName to board
    JKisio('#board_container')[0].setAttribute('data-section', sectionName);

    updateURLHistory(sectionName);
    document.body.dispatchEvent(new CustomEvent('stop_ajax_calls'));
    document.body.dispatchEvent(new CustomEvent(`${sectionName}_container_displayed`));
}

export function hideAllContents() {
    const boardTitleElements = JKisio(params.boardTitleId);
    const boardContent = JKisio('#board_content');

    // hide title
    boardTitleElements.children().each(function () {
        if (this.classList.contains('selected')) {
            const selectedTitle = this.id?.replace('_title', '');
        }

        JKisio(this).removeClass('selected');
    });

    // hide content
    boardContent.children().each(function () {
        JKisio(this).hide();
    });
}

function displayTargetSection() {
    const sectionName = getSectionName();
    JKisio("#board_container").removeClass('gl-hide');
    if (sectionName) {
        displaySelectedSection(sectionName);
    } else {
        manageShowLines();
    }
}

function initAutocompleteInputs() {
    window.autocomplete = {
        from: JKisio(params.fromId).val(),
        to: JKisio(params.toId).val()
    };

    window.autocompleteHidden = {
        from: JKisio(params.fromIdHidden).val(),
        to: JKisio(params.toIdHidden).val()
    };
}

export function updateURLHistory(targetSectionName) {
    const href = window.location.href;
    const currentsectionName = getSectionName();
    let route = targetSectionName !== currentsectionName ? `/${window.Kisio.locale}/${Kisio.type_product}/${targetSectionName}` : href;

    // store previous href
    window.history.stored_link = window.history.stored_link || {};
    if (window.history.stored_link[targetSectionName]) {
        route = window.history.stored_link[targetSectionName];
    }

    window.history.stored_link[currentsectionName] = href;

    // update current href
    const newState = (window.history.state || 0) + 1;
    window.history.replaceState(newState, 'Pushed: ' + newState, route);
};

function getSectionName() {
    // get selected module from url
    const routeParams = window.location.pathname.split('/');

    return routeParams[3];
}
