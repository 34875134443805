import { AutocompleteView } from "./views/autocomplete.view.js";

Kisio.Callbacks.autocomplete = function() {
    var autocomplete = new Backbone.Collection;
    var dataCache = new Backbone.Collection;
    var configCache = new Backbone.Collection;
    // configCache.add(CanalTP.AutocompleteConfigs);
    JKisio('.autocompletable').each(function() {
        autocomplete.add([
            new AutocompleteView({
                el: this,
                dataCache: dataCache,
                configCache: configCache
            })
        ]);
    });
};