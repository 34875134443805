import {clearMap, hideAllLayers} from "../../modules/map";
import {manageShowLines} from "../../modules/map/lines";
import {updateURLHistory} from "../index";
import { resetCenterMap } from "../../modules/map";
import {executeIfMapExist} from "../../modules/map/function";
/**
 * Click on back icon to go back to boarder initial state 
 */
export function goBackToBoard() {
    //hide All layers on Map
    clearMap(false);
    updateURLHistory("");
    document.title = Translator.trans('canaltp_pnpcore.title');
    Kisio.section_active = 'home';

    JKisio('#board_container')[0].removeAttribute('data-section');

    document.body.dispatchEvent(new CustomEvent('manage_map_layers', {
        detail: {
            section_id: "home"
        }
    }));

    //Click on back button to set the board with closed titles
    // recover initial board container state
    recoverBoardContainer();
    JKisio('.board_title em').addClass('section_icon');
    JKisio('.board_title').each(function() {
        if(JKisio(this).hasClass('selected')) {
            JKisio(this).removeClass('selected')
        }
    });
    const boardContent = JKisio('#board_content');
    // hide content
    boardContent.children().each(function () {
        JKisio(this).hide();
    });
    // show subtitles again
    JKisio('.subtitle, .under_subtitle').each(function() {
        showElement(this);
    });
    //show proximity
    showElement('#footer_proximity');
    //hide
    hideElement('#board_title_container .ikisio-back');
    // Show favorites
    JKisio('#board_footer').show();

    executeIfMapExist(() => {
        resetCenterMap({
            latitude: window.Kisio?.map_config.latitude,
            longitude: window.Kisio?.map_config.longitude,
            zoom: window.Kisio.map_config.zoom
        });

        manageShowLines();

        if (Kisio.networks.length === 0 ) {
            JKisio('.leaflet-tooltip , .leaflet-marker-icon').hide();
        }
    })
}

/**
 * open one of the board titles
 */
export function openTitle() {
    //Expand board container
    expandBoardContainer();
    JKisio('.board_title em').removeClass('section_icon');
    JKisio('#board').addClass('expanded');
    // Remove Subtitles 
    JKisio('.subtitle, .under_subtitle').each(function() {
        hideElement(this);
    });
    //Show back button 
    showElement('#board_title_container .ikisio-back');
    // hide proximity
    hideElement('#footer_proximity');
    // hide favorites
    JKisio('#board_footer').hide();
}

/**
* Hide border subtitles
*/
export function hideElement(element) {
    JKisio(element).addClass('gl-hide');
}

/**
* show border subtitles
*/
export function showElement(element) {
    JKisio(element).removeClass('gl-hide');
}

/**
 * Expand board container
 */
export function expandBoardContainer() {
    let container = JKisio('#board_title_container');
    container?.addClass('open');
    container?.closest('#board_container').addClass('board_open');
}

/**
 * Recover board container state
 */
function recoverBoardContainer() {
    let container = JKisio('#board_title_container');
    container.closest('#board_container').removeClass('board_open');
    container.removeClass('open');
    JKisio('#board').removeClass('expanded');
}