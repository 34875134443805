import {initializeBoard} from "./structure";
import { hideAllContents } from "./structure";
import { showBookmarkWrapper } from "./plugnplay/favorites";
import { displayMap } from "./plugnplay/leaf-core";

export * from './plugnplay/historic_cookies';
export * from './modules/utils';

document.title = Translator.trans('canaltp_pnpcore.title');

document.addEventListener('DOMContentLoaded', function () {
    initializeBoard();

    document.body.dispatchEvent(new CustomEvent('initialize_proximity_module'));

    if (Kisio.type_product == "standalone") {
        document.body.addEventListener('standalone_display_bookmark', (e) => {
            Kisio.sectionOrigin = e.detail.section;
            if (e.detail.section == 'proximity') {
                document.body.dispatchEvent(new CustomEvent('hide_proximity_bundle'));
            }else {
                hideAllContents();
            }
            showBookmarkWrapper();
        })
    }

    document.body.addEventListener('initialize_map', (e) => {
        displayMap();
    })
});
