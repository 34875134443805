export var globalBodyPopup = Backbone.View.extend({
    params: {
        routeName: 'information_popup',
        popupClass: '.kisio-global-popup-full',
        closePopupId: '#kisio-popup-close',
        networkId: '',
        networkLabel: '',
        share: '',
        shareUrl: '',
        shareMailUrl: '',
        shareTag: 'shareDisplayed',
        shareMailTag: 'shareMailDisplayed',
        page_type: '',
        occupancy: '',
        geo_velo: '',
        iframe_url: '',
        journey_selected: ''
    },

    /**
     * Initialisation
     * @param {object} options configuration
     */
    initialize: function (options) {
        this.params = {...this.params, ...options }
        this.showPopup();
    },

    /**
     * function to display popup
     */
    showPopup: function () {
        const self = this;
        let type_product = (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '';
        let link = Routing.generate(this.params.routeName + '_' + this.params.page_type, {
            type_product: type_product,
            page_type: this.params.page_type
        });
        if (self.params.networkId) {
            link += '?' + 'networkId=' + self.params.networkId;
            self.params.networkId = '';
        }
        if (self.params.networkLabel) {
            link += '&' + 'networkLabel=' + self.params.networkLabel;
            self.params.networkLabel = '';
        }

        if (self.params.share) {
            let urlInfo = self.getUrlParameters();
            if (self.params.tag && self.params.tag === self.params.shareTag) {
                if (self.params.shareUrl) {
                    link += '?share=' + self.params.shareUrl + urlInfo.urlParams;
                } else {
                    link += '?share=' + urlInfo.url;
                }
                self.params.share = self.params.shareUrl = '';
            }
            if (self.params.tag && self.params.tag === self.params.shareMailTag) {
                if (self.params.shareMailUrl) {
                    link += '?mail=' + self.params.shareUrl + urlInfo.urlParams;
                } else {
                    link += '?mail=' + urlInfo.url;
                }
                self.params.share = self.params.shareMailUrl = '';
            }
        }
        if (self.params.occupancy) {
            link += '?occupancy';
        }
        if (self.params.geo_velo) {
            link += '?geo_velo&iframe_url='+self.params.iframe_url;
            self.params.networkLabel = '';
        }
        if (self.params.journey_selected){
            link += '&journey_selected_index='+self.params.journey_selected;
        }
        if (self.params.favorite_parent_id) {
            link += '?favorite_parent_id='+self.params.favorite_parent_id;
        }
        // get share object
        JKisio.ajax({
            url: link
        }).done(function (result) {
            if (!JKisio(self.params.popupClass).is(':visible')) {
                JKisio('body').append(result);
                if (self.params.tag && self.params.tag === self.params.shareMailTag) {
                    JKisio('#mail_title').focus();
                }
                if (self.params.tag && self.params.tag === self.params.shareTag) {
                    JKisio('.kisio-popup-network-information').focus();
                }
                // close popup
                self.hideOnClickOutside('kisio-global-popup');
                JKisio(self.params.closePopupId).on('keyup', function (e) {
                    if (e.keyCode === 32 || e.keyCode === 13) {
                        JKisio(self.params.closePopupId).trigger('click');
                    }
                });
                JKisio(self.params.closePopupId).click(function (e) {
                    JKisio(self.params.popupClass).remove();
                })
            }
        });
    },

    hideOnClickOutside: function (element) {
        JKisio(document).click(function (e) {
            let target = e.target;
            let targetClass = JKisio(target).attr('class');
            if (targetClass && targetClass === 'kisio-global-popup-full' ) JKisio(target).remove();
        })
    },

    arrayToQuery: function param(object, type) {
        let parameters = [];
        for (let property in object) {
            if (object.hasOwnProperty(property)) {
                parameters.push(encodeURI(type + '[' + property + ']=' + object[property]));
            }
        }

        return parameters.join('&');
    },

    getUrlParameters: function () {
        let urlInfo = {
            url: new URLSearchParams(window.location.href),
            urlParams: new URLSearchParams(window.location.search)
        };

        return urlInfo;
    }
});

/**
 * Function to test via Regex the format of e-mail in Mail Sharing PopUp
 * @param elt
 * @returns {boolean}
 */
function testMailFormat(elt) {
    let regexMail = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm;
    let value = JKisio(elt).val().trim();
    if (regexMail.exec(value) === null) {
        let errorHTML = '<div class="popup-mail-error"><i class="ikisio ikisio-warning"></i> <span>'+Translator.trans('toolbox.share.content.journey.error.email')+'</span></div>';
        JKisio(elt).before(errorHTML);
        setTimeout(function () {
            JKisio('#contact_form .popup-mail-error').remove();
        }, 3000);
        return false;
    } else {
        return true;
    }
}

/**
 * Function to deal with empty field in Mail Sharing PopUp
 * @param elt
 * @returns {boolean}
 */
function dealWithEmptyField(elt) {
    let errorHTML = '<div class="popup-mail-error"><i class="ikisio ikisio-warning"></i> <span>'+Translator.trans('toolbox.share.content.journey.error.empty')+'</span></div>';
    JKisio(elt).before(errorHTML);
    setTimeout(function () {
        JKisio('#contact_form .popup-mail-error').remove();
    }, 3000);

    return false;
}

/**
 * Function to be run when interacting with Submit button of E-Mail Sharing PopUp
 * @returns {Promise<unknown>}
 */
function onSubmitMail() {
    if (JKisio('#contact_form #contact_email_from').val() !== undefined && JKisio('#contact_form #contact_email_from').val() !== '') {
        if (!testMailFormat('#contact_form #contact_email_from')){
            return;
        }
    } else {
        dealWithEmptyField('#contact_form #contact_email_from');
        return;
    }
    if (JKisio('#contact_form #contact_email_to').val() !== undefined && JKisio('#contact_form #contact_email_to').val() !== '') {
        if (!testMailFormat('#contact_form #contact_email_to')){
            return;
        }
    } else {
        dealWithEmptyField('#contact_form #contact_email_to');
        return;
    }
    if (JKisio('#contact_form #contact_lastname').val() === undefined || JKisio('#contact_form #contact_lastname').val() === '') {
        dealWithEmptyField('#contact_form #contact_lastname');
        return;
    }
    if (JKisio('#contact_form #contact_firstname').val() === undefined || JKisio('#contact_form #contact_firstname').val() === '') {
        dealWithEmptyField('#contact_form #contact_firstname');
        return;
    }
    return new Promise(function(resolve, reject) {
        if (grecaptcha === undefined) {
            console.log('Recaptcha not defined.');
            reject();
        }
        let response = grecaptcha.getResponse();
        if (!response) {
            console.log('Could not get recaptcha response.');
            reject();
        }
        JKisio('#contact_form').submit(function(e) {
            e.preventDefault();
            let _this = this;
            JKisio('#loading-box').css("visibility", "visible");
            setTimeout(function () {
                let htmlMessage = '';
                let query = decodeURIComponent(JKisio(_this).serialize());
                let regex = /params=(.+)&contact\[email_from\]=(.+)&contact\[email_to\]=(.+)&contact\[lastname\]=(.+)&contact\[firstname\]=(.+)&contact\[content\]=(.*)&g-recaptcha-response=&contact\[_token\]=(.+)/;
                let m, params, contact_email_from, contact_email_to, contact_lastname, contact_firstname, contact_content, contact_token, message;
                if ((m = regex.exec(query)) !== null) {
                    params = m[1];
                    contact_email_from = m[2];
                    contact_email_to = m[3];
                    contact_lastname = m[4];
                    contact_firstname = m[5];
                    contact_content = m[6];
                    contact_token = m[7];
                }
                JKisio.ajax({
                    type: "POST",
                    url: Routing.generate('information_popup_journey',
                        {
                            type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                            page_type: 'mail',
                            mail: ''
                        }),
                    data: {
                        gRecaptchaResponse: response,
                        params: params,
                        contact_email_from: contact_email_from,
                        contact_email_to: contact_email_to,
                        contact_lastname: contact_lastname,
                        contact_firstname: contact_firstname,
                        contact_content: contact_content,
                        contact_token: contact_token
                    },
                    async:false,
                    success: function(data) {
                        JKisio('#loading-box').css('visibility','hidden');
                        message = Translator.trans('contact.form.success.flash');
                        htmlMessage += '<div class="mail-message-container mail-message-success"><i class="ikisio ikisio-validate"></i><div class="message">' + message + '</div></div>';
                        JKisio('.kisio-popup-body').prepend(htmlMessage);
                        setTimeout(function () {
                            JKisio('.kisio-global-popup-full').remove();
                        }, 5000);
                    },
                    error: function (xhr, status, error) {
                        JKisio('#loading-box').css('visibility','hidden');
                        message = Translator.trans('contact.form.error');
                        htmlMessage += '<div class="mail-message-container mail-message-error"><i class="ikisio ikisio-traffic"></i><div class="message">' + message + '</div></div>';
                        JKisio('.kisio-popup-body').prepend(htmlMessage);
                    }
                });
            }, 150);
        });
        JKisio('#contact_form').submit();
        grecaptcha.reset();
    });
}