/**
 * Get custom image by line, and add disruption if available
 *
 * @param line
 * @param arrayOfDisruption
 * @param customClass
 * @param stop_area_id
 * @param show_name
 */
export var getLinePicto = function(line, arrayOfDisruption, customClass, stop_area_id, show_name, coords, lines_selection) {
    // Reformat Kisio.lines object to reassign key with value
    let KLines = {};
    Kisio.lines.forEach( function(item, index) {
        JKisio.extend(KLines, item);
    });

    //Title for tooltip
    let title = '';
    if (typeof arrayOfDisruption !== 'undefined' && typeof arrayOfDisruption[line.id] !== 'undefined') {
        title += arrayOfDisruption[line.id].cause + '\r\n';
    }
    title += line.commercial_mode.name + ' ' + line.code + ' : ' + line.name;
    let aria_label = title;
    let role = false;
    if (lines_selection !== undefined) {
        if (lines_selection) {
            aria_label = Translator.trans('schedule.result.next_departures.selected_line') + aria_label;
        }
        role = true;
    }
    //Url to redirect
    let url = '?schedule[stop_area_lines][autocomplete]=' + line.name + '&schedule[stop_area_lines][autocomplete-hidden]=' + line.id;

    let html = '<span class="lines '+ (customClass ?? "") +'" data-html="true"' + (role ? ' role="button"': '') + ' data-stop-area="'+stop_area_id+'" data-title="' + title + '" data-url="' + url + '" href="' + url + '" title="' + title + '" aria-label="' + aria_label + '"';

    if (Kisio.lines !== null && typeof KLines[line.id] !== 'undefined') {
        html += '>' +
            '<img src="'+KLines[line.id]+'" class="picto_line"  alt="" />' +
            '</span>';
    } else {
        if (line.text_color === '') {
            line.text_color = '000';
        }
        if (line.color === '' || line.color === line.text_color) {
            line.color = 'FFFFFF';
        }
        html += ' style="background-color:#' + line.color + '; color:#' + line.text_color + ';';
        if (line.color === 'FFFFFF') {
            html += 'border: solid 1px #' + line.text_color + ';';
        }
        html += '">' + line.code + '</span>';

    }

    if (typeof show_name !== 'undefined') {
        html += ' <div class="lines_in_popup" data-line="' + line.id + '"';
        if (typeof stop_area_id !== 'undefined') {
            html += ' data-stop-area="'+stop_area_id+'"';
        }
        if (typeof coords !== 'undefined') {
            html += ' data-coords="'+coords+'"';
        }
        html += ' >' + line.name + '</div>';
    }


    //Disruption
    if (typeof arrayOfDisruption !== 'undefined' && typeof arrayOfDisruption[line.id] !== 'undefined') {
        html += '<img class="disruption-type" src="' + arrayOfDisruption[line.id].image + '" alt="" />';
    }

    return html;
};

/**
 * Get image by mode
 * Configurated in yaml file, under "modes_img"
 *
 * @param commercial_mode
 * @returns {string}
 */
export var getModePicto = function (commercial_mode) {
    let mode = commercial_mode.toLowerCase();
    let img = window.Kisio.modes_img[mode];
    if (typeof window.Kisio.modes_img[mode] === 'undefined') {
        img = window.Kisio.modes_img['commercial_mode:'+mode];
    }
    return '<img src="' + img + '" class="icon-mode" alt="" />';
};