import { desktopBreakPoint, mapPopupConfig } from "../../plugnplay/leaf-core";

export function manageGeoMarkers(fieldType, address, latlng, type, autocompleteHiddenValue, currentMap = null, fitbound = true) {
    window.leaflet = window.leaflet || {};
    window.leaflet.markers = window.leaflet.markers || {};
    currentMap = currentMap || window.leaflet.map;

    if (JKisio('#mapid').is(':visible')) {
        if (!currentMap) {
            return;
        }

        if (window?.leaflet?.markers?.from && window?.leaflet?.markers?.from?.options?.title === fieldType) currentMap.removeLayer(window.leaflet.markers.from);
        if (window?.leaflet?.markers?.to && window?.leaflet?.markers?.to?.options?.title === fieldType) currentMap.removeLayer(window.leaflet.markers.to);
        if (fieldType === 'from') {
            window.leaflet.markers.from = window.Kisio.MapPopup.getCustomerMarker(fieldType, latlng);
            window.leaflet.markers.from.addTo(currentMap);
            window.leaflet.markers.number = window.leaflet.markers.number ?? 0 + 1;
        }
        if (fieldType === 'to') {
            window.leaflet.markers.to = window.Kisio.MapPopup.getCustomerMarker(fieldType, latlng);
            window.leaflet.markers.to.addTo(currentMap);
            window.leaflet.markers.number = window.leaflet.markers.number ?? 0 + 1;
        }
        if (address)
            JKisio('#search_' + fieldType + '_autocomplete').val(address).trigger('change');
        // set value of input cached when search from map
        if (type === 'map') {
            let lnglat = latlng.lng + ';' + latlng.lat;
            JKisio('#search_' + fieldType + '_autocomplete-hidden').val(lnglat).trigger('change');
        }
        if (type === 'prox') {
            JKisio('#search_' + fieldType + '_autocomplete-hidden').val(autocompleteHiddenValue).trigger('change');
        }

        if(fitbound === true) {
            if (window?.leaflet?.markers?.from && window?.leaflet?.markers?.to) {
                currentMap.fitBounds([window.leaflet.markers.from._latlng, window.leaflet.markers.to._latlng], mapPopupConfig());
            } else {
                currentMap.fitBounds([latlng], mapPopupConfig());
            }
        }

        if (JKisio('body').outerWidth(true) < desktopBreakPoint) {
            currentMap.setZoom(12);
        }
    }
};