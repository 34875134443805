/**
 * Function to create entry in local storage by convert JSON to string for the local storage value
 * @param name
 * @param value
 */
export function set_local_storage_item(name, value) {
    const stringValue = JSON.stringify(value);
    try {
        window.localStorage.setItem(name, stringValue);
    }catch (e) {
        if (e.code === DOMException.QUOTA_EXCEEDED_ERR && storage.length === 0) {
            console.log("Private here");
        }
    }
}

/**
 * Function to read the local storage entry and return a JSON for its string value
 * @param name
 * @returns {any}
 */
export function read_local_storage_item(name) {
    let result = null;
    try {
        result = window.localStorage.getItem(name);
        result && (result = JSON.parse(result));
    }catch (e) {
        if (e.code === DOMException.QUOTA_EXCEEDED_ERR && storage.length === 0) {
            console.log("Private here");
        }
    }
    return result;
}

/**
 * Function to erase local storage entry
 * @param name
 */
export function delete_local_storage_item(name) {
    try {
        window.localStorage.removeItem(name);
    }catch (e) {
        if (e.code === DOMException.QUOTA_EXCEEDED_ERR && storage.length === 0) {
            console.log("Private here");
        }
    }
}

/**
 * Function to erase all local storage entries
 */
export function delete_all_local_storage_items() {
    try {
        window.localStorage.clear();
    }catch (e) {
        if (e.code === DOMException.QUOTA_EXCEEDED_ERR && storage.length === 0) {
            console.log("Private here");
        }
    }
}