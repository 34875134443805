/**
 * Function to create cookie by convert JSON to string for the cookie value, and we set the Expire date to 2038…
 * (or to 1970 if we want to delete the cookie)
 * @param name
 * @param value
 */
export function create_cookie(name, value, deleteCookie) {
    let date = new Date(2147483647 * 1000).toUTCString()
    if (deleteCookie !== undefined && deleteCookie) {
        date = 'Thu, 01 Jan 1970 00:00:01 GMT';
    }
    let cookie = [name, '=', JSON.stringify(value), ';expires=' + date + '; domain=', window.location.host.toString(), '; path=/;'].join('');
    document.cookie = cookie;
}

/**
 * Function to read the cookie and return a JSON for its string value
 * @param name
 * @returns {RegExpMatchArray}
 */
export function read_cookie(name) {
    let result = document.cookie.match(new RegExp(name + '=([^;]+)'));
    if (result !== null) {
        result = JSON.parse(result[1]);
    }

    return result;
}

/**
 * Function to erase all cookies from the browser
 */
export function erase_all_cookies() {
    let cookies = document.cookie.split(";");
    for (const cookie of cookies) {
        let name = cookie.split("=")[0];
        erase_cookie(name);
    }
}

/**
 * Function to erase cookie by sending the param deleteCookie to true at the create_cookie function
 * @param name
 */
function erase_cookie(name) {
    create_cookie(name, "", true);
};