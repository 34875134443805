import { showStopPointsByLine } from "./line";
import { getImgByMode, manageDisriptions } from "./utils";
import { getLinePicto, getModePicto } from "../../../plugnplay/customize_client";
import {displayLayersSection} from "../core/clearMap";

export function manageShowLines() {
    if(Kisio.show_lines_on_board === 1) {
        displayAllLines();

        // Event listener to manage map layers
        document.body.addEventListener('manage_map_layers', function (e) {
            if (e.detail?.section_id === 'home') {
                manageMapLayers();
            }
        });
    }
}

function displayAllLines() {
    Object.keys(Kisio.networks).forEach(function (networkId) {
        showLinesByNetworkId(networkId);
    });
}

/**
 * Show all lines for a network solo only !
 *
 * @param network_id
 * @param network_conf
 */
function showLinesByNetworkId(network_id) {
    const xhr = JKisio.ajax({
        url: Routing.generate('r_public_transport_objects_filtered',{
            type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
            public_transport_object_type: 'networks',
            public_transport_object_id: network_id,
            action: 'lines',
            count: Kisio.max_lines,
            forbidden_uris: Kisio.modes_to_exclude
        }),
        async: true,
        dataType: 'json',
        success: function (data) {
            let lines = data.lines;
            if (lines && lines.length > 0) {
                drawLines(lines, data);
            }
        },
        error: function(xhr, status, error) {
            console.log(error);
        }
    });

    document.body.addEventListener('stop_ajax_calls', function () {
        xhr?.abort();
    }, {once: true});
};

/**
 * display line details
 */
function displayLineDetail(line, tooltipContent, marker) {
    const middleGeojsonIndex = Math.ceil(line.geojson.coordinates.length / 2);
    const middleCoordArray = line.geojson.coordinates[middleGeojsonIndex];
    const tooltipCoord = middleCoordArray[Math.ceil(middleCoordArray.length / 2)]
    const tooltip = marker.bindTooltip( tooltipContent, {
        interactive: true,
        permanent: true,
        pane: 'tooltipPane',
        className: 'line-tooltip line-tooltip__home',
        opacity: 1,
        layer_section: ['home']
    });

    tooltip.openTooltip().setLatLng([tooltipCoord[1], tooltipCoord[0]]);
}

function diplayTerminus(line) {

    let icon = L.icon({
        iconUrl: "/bundles/canaltppnpcore/images/picto/24/terminus.jpeg",
        iconSize: [0, 0],
        iconAnchor: [0, 0]
    });
    line.routes.forEach(function (route) { 
        let marker = L.marker([route.direction.stop_area.coord.lat, route.direction.stop_area.coord.lon], {icon: icon}); //.addTo(window.leaflet.map);
        Kisio.layerGroup.home.homeLayers.addLayer(marker);
        marker.bindTooltip(route.direction.stop_area.name, {className: "terminus-tooltip", layer_section: ['home']}).openTooltip();
        let tooltip = marker.getTooltip()._container;

        Kisio.layerGroup.home.homeLayers.addTo(window.leaflet.map);

        JKisio(tooltip).css({
            "border": "3px solid #" + line.color, 
        });
    });
}

/**
 * Draw lines
 * @param {*} lines 
 */
function drawLines(lines, data) {
    let arrayOfDisruption = [];
    if (typeof data.disruptions !== "undefined") {
        let disruptions = data.disruptions;
        arrayOfDisruption = manageDisriptions(disruptions);
    }

    const lineTooltips = [];
    lines.forEach(function (line) {
        let total = 0;
        if (line.geojson.coordinates.length > 0) {
            let image = getImgByMode(line.commercial_mode.name.toLowerCase());
            for (const coordinate of line.geojson.coordinates) {
                for (const element of coordinate) {
                    if (total === Math.round(coordinate.length / 10) && typeof image !== "undefined") {
                        let icon = L.icon({
                            iconUrl: image,
                            iconSize: [0, 0], // size of the icon
                            iconAnchor: [element[1], element[0]], // point of the icon which will correspond to marker's location
                        });
                        const marker = L.marker([element[1], element[0]], {icon: icon, keyboard: false, layer_section: ['home']});//.addTo(window.leaflet.map);
                        Kisio.layerGroup.home.homeLayers.addLayer(marker)
                        lineTooltips.push({
                            marker: marker,
                            tooltip: getModePicto(line.commercial_mode.id) +  getLinePicto(line, arrayOfDisruption),
                            line: line
                        });
                    }
                    total++;
                }
            }
            const myStyle = {
                "color": "#" + line.color,
                "weight": 5,
                "opacity": 1
            };
            
            Kisio.layerGroup.home.homeLayers.addLayer(L.geoJSON(line.geojson, {style: myStyle, layer_section: ['home']}));
            Kisio.layerGroup.home.homeLayers.addTo(window.leaflet.map);
        } else {
            console.log('No coordinates found for line: ' + line.id)
        }
    });

    manageMapLayers(lineTooltips);

    // need refato (only for homepage)
    window.leaflet.map.on('zoomend', function() {
        if (document.querySelector('.board_open')) {
            return;
        }
        
        manageMapLayers(lineTooltips);
    });
}

function manageMapLayers(lineTooltips) {
    if (!JKisio('#board_title_container').is(":visible")) {
        return;
    }

    const initialZoom = Kisio.map_config.zoom;
    const currentZoom = window.leaflet.map.getZoom();
    const linesLabel = JKisio('.line-tooltip');
    const linesTerminusLabel = JKisio('.terminus-tooltip');

    displayLayersSection('home');

    if (currentZoom === initialZoom && !linesLabel.is(':visible')) {
        // display cached lines label
        if (linesLabel.length > 0) {
            linesLabel.show();
            return;
        }

        if (linesLabel.length === 0 && lineTooltips) {
            Object.values(lineTooltips).forEach(function (lineTooltip) {
                displayLineDetail(lineTooltip.line, lineTooltip.tooltip, lineTooltip.marker);
            });
        }
    }

    if (currentZoom >= initialZoom+1 && !linesTerminusLabel.is(':visible') && lineTooltips) {
        let stopPointAlreadyExiste = false;
        Object.values(lineTooltips).forEach(function (lineTooltip) {
            diplayTerminus(lineTooltip.line);
            window.leaflet.map.eachLayer(function (layer) {
                if (layer.options?.line_id === lineTooltip.line.id && layer.options.layer_section?.includes('home')) {
                    stopPointAlreadyExiste = true;
                    layer.setStyle({
                        opacity: 1,
                        fillOpacity: 1
                    });
                }

                if (layer.getTooltip()) {
                    layer.getTooltip().setOpacity(1);
                }
            });

            if (!stopPointAlreadyExiste) {
                showStopPointsByLine(lineTooltip.line);
            }
        });
    }

    if(currentZoom === initialZoom - 1){
        JKisio('.line-tooltip').hide();
    }

    if (currentZoom <= initialZoom) {
        JKisio('.terminus-tooltip').hide();
        Object.values(window.leaflet.map._layers).forEach(function(layer) {
            if (layer instanceof L.CircleMarker && layer.options.layer_section?.includes('home')) {
                layer.setStyle({
                    opacity: 0,
                    fillOpacity: 0
                });
            }
        });
    }
}
