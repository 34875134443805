import { manageGeoMarkers } from '../modules/map-popup';
import { read_cookie, create_cookie } from './cookies';
import { isIE9, isIE10, isIE11 } from '../plugnplay/browser';
import { set_local_storage_item, read_local_storage_item } from './localStorage';
import {BookmarkClientFactory} from "bookmark_bundle/client/bookmark-client-factory";
import { getLinePicto } from 'pnp_core_bundle/plugnplay/customize_client';
import {executeIfMapExist} from "pnp_core_bundle/modules/map/function";

/**
 * String.prototype.replaceAll() polyfill
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function(str, newStr){
        // If a regex pattern
        if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
            return this.replace(str, newStr);
        }
        // If a string
        return this.replace(new RegExp(str.replace(/[.()]/g, '\\$&'), 'g'), newStr);
    };
}

if (Kisio.enable_historic_search){
    
    /**
     * Function to add Historic of Journey search to Cookie
     * @param {string} label Valeur du champs texte
     * @param {string} uri Valeur du champs caché
     */
    function addHistoricSearchToCookie(label, uri, coord) {
        //Value to insert into cookie
        let value = {
            input: encodeURIComponent(label),
            inputHidden: encodeURIComponent(uri),
            coord: coord,
        };

        //Read the cookie
        let historicCookie = read_cookie('historic');
        if (historicCookie !== undefined && historicCookie !== null && historicCookie.length > 0) {
            //Check if the value is already present
            let valueAlreadyPresent = false;
            for (const element of historicCookie) {
                if (element.input !== undefined && element.inputHidden !== undefined && element.input === value.input && element.inputHidden === value.inputHidden) {
                    valueAlreadyPresent = true;
                }
            }
            if (valueAlreadyPresent === false) {
                //Insert value into cookie
                historicCookie.push(value);
                //Update its content as soon as there are 3 elements
                if (historicCookie.length > Kisio.historic_limit_value){
                    historicCookie.splice(0,1);
                }
                // if Cookie already exist, we need to complete it
                create_cookie('historic', historicCookie);
            }
        } else {
            //cookie doesn't exist, we create it
            let cookieValue = [];
            cookieValue.push(value);
            create_cookie('historic', cookieValue);
        }
    };

    /**
     * Function to show the historic list
     * @param elt
     */
    function showHistoricList(elt){
        // Remove the list if it exists
        JKisio('.autocomplete .historic-list').remove();

        let client = new BookmarkClientFactory().createClient();

        let addressBookmark = client.getSync().filter((bookmark) => {
            return bookmark.type.includes("address")
        });

        //Read the cookie
        let historicList = read_local_storage_item('historic');
        if (!historicList && addressBookmark.length === 0) {
            return;
        }

        historicList = historicList || [];

        historicList.reverse();

        let parentHtlml = JKisio(elt).parent();
        let idParentHtml = parentHtlml.attr('id');
        let idInput = JKisio(elt).attr('id');
        const dataType = idParentHtml.replace('search_', '');

        if (historicList !== undefined && historicList !== null ) {
            // Build the list in HTML
            let htmlUl = "<ul class='historic-list'>";
                htmlUl += `<div class="locate-position location-autocomplete" id="location-icon-from" data-type="${dataType}">`
                + '<em role="button" class="ikisio ikisio-locate"></em>'
                + '<span id="location-text">'+ Translator.trans('places.autocomplete.position') +'</span>'
                + '</div>';

            let label = "";

            if(historicList.length > 0) {
                label = "<label class='historic-label'>" + Translator.trans('historic.label') + "</label>";
            }

            const htmlLi = historicList
                .filter(({ coord }) => coord)
                .map(({ input, inputHidden, coord, lines, type }) => {
                    const decodedInput = decodeURIComponent(input);
                    const decodedInputHidden = decodeURIComponent(inputHidden);
                    const coordString = `${coord.lat};${coord.lon}`;
                    let currentMode = '';
                    const linesHtml = lines && lines.length
                    ? `
                    <div class="lines-container">
                        ${lines.map((line) => {
                            let modeHtml = '';
                            if (line.mode !== currentMode) {
                                modeHtml = `<div class="icon-container"><em class="ikisio icon-history-mode ikisio-${line.mode}"></em></div>`;
                                currentMode = line.mode;
                            }
                            return `${modeHtml}<div>${getLinePicto(line, 'undefined', 'autocomplete-line-code')}</div>`;
                            }).join('')}
                    </div>
                    `
                    : '';
                    return `
                    <li class="historic-item" data-coord="${coordString}" id="${decodedInputHidden}">
                        <div class="header">
                            <em class="ikisio icon-autocomplete-type ikisio-${type}"></em>
                            <span>${decodedInput}</span>
                        </div>
                        ${linesHtml}
                    </li>
                    `;
                })
                .join('');
            let htmlEnd = "</ul>"
            let html = htmlUl + label + htmlLi + htmlEnd;
            parentHtlml.append(html);
            // Manage the css on focus
            JKisio('#'+idParentHtml).addClass('focus-in');
            if (idInput === "search_from_autocomplete") {
                JKisio('#journey-form-reverse').hide();
                JKisio('#search_to .historic-list').remove();
                JKisio('#search_to').removeClass('focus-in');
            } else if (idInput === "search_to_autocomplete") {
                JKisio('#journey-form-reverse').hide();
                JKisio('#search_from .historic-list').remove();
            } else if (idInput === "schedule_stop_area_lines_autocomplete") {
                JKisio('.historic-list').remove();
            } else if (idInput === "proximity_search_uri_autocomplete") {
                JKisio('.historic-list').remove();
            }
            filterHistoricList(elt);
        }

    };

    function showBookmarkList(elt){

        let client = new BookmarkClientFactory().createClient();

        let addressBookmark = client.getSync().filter((bookmark) => {
            return bookmark.type.includes("address")
        });

        if(addressBookmark.length === 0 ) {
            return;
        }

        // Remove the list if it exists
        let label = "<label class='historic-label'>Favoris</label>";

        const htmlLi = addressBookmark
            .map(({ id, data }) => {
                return `
                    <li class="historic-item" data-coord="${escapeHtml(data.coord)}" id="${id}">
                        <div class="header">
                            <em class="ikisio icon-autocomplete-type ${escapeHtml(data.icon)}"></em>
                            <span class="removable-text">${escapeHtml(data.name)}</span>
                          
                        </div> 
                        <div class="content">
                            <span>${escapeHtml(data.address)}</span>
                        </div>
                    </li>
                    `;
            })
            .join('');

        let html = label + htmlLi;
        JKisio(".historic-list > .locate-position").after(html);
    };

    function escapeHtml(unsafe) {
        return JKisio('<div>').text(unsafe).html();
    };

    /**
     * Function used to filter the Historic list based on inputed value
     * @param elt
     */
    function filterHistoricList(elt) {
        // Before filtering, we make sure that the historic entries container is visible
        // in the case of one of those should be "appearing" depending on the filter
        JKisio('.historic-list').show();
        // We get the value inputed, trimmed of trailing whitespaces
        let inputValueString = elt.value.trim(' ');
        if (!(isIE9 || isIE10 || isIE11)) {
            // On all modern browsers, we normalize the string to deconpose any accented characters and then remove the accent
            inputValueString = inputValueString.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        }
        // We filter only if the value is not empty
        if (inputValueString.length > 0) {
            // We build an array of terms to search by differentiating them based on whitespace separation in the inputed value
            let inputValuesArray = inputValueString.split(' ');
            // We check if the split has generated any empty string in the Search Terms array
            for (let i = 0; i < inputValuesArray.length; i++) {
                if (inputValuesArray[i] === '') {
                    // When it is the case, we dropped them from the array
                    inputValuesArray.splice(i,1);
                }
            }
            // We start building the string used later for the substitution Regex by joining the searh array terms with a | in order to search alternatively all terms
            let inputValuesStrRegex = '('+inputValuesArray.join('|')+')';
            // We build the Regex with Global & Insensitive flags to search for all occurences & despite cases
            let regex = new RegExp(inputValuesStrRegex.replace(/[.()]/g, '\\$&'), 'gi');
            // We get all historic items to cycle through them to decide if we need to hide them or keep them and highlight the search terms
            let historicList = JKisio('.historic-list li');
            if (Array.isArray(historicList)) {
                for (const historic of historicList) {
                    // We get the text ONLY form the historic item
                    let originalStr = JKisio(historic).text();
                    if (!(isIE9 || isIE10 || isIE11)) {
                        // On all modern browsers, we normalize the string to deconpose any accented characters and then remove the accent
                        originalStr = originalStr.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                    }
                    // We need to determine before making the sustitution, if all terms are found in the item string
                    // We initiate a variable which will increase when we found an occurence of a search term in the item string
                    let toSubstitute = 0;
                    for (const input of inputValuesArray) {
                        let reg = new RegExp(input.replace(/[.()]/g, '\\$&'), 'gi');
                        if (reg.test(originalStr)) {
                            toSubstitute++;
                        }
                    }
                    // If the variable previously increased is equal to the length of the Search terms array, it means that all terms have been found in the historic item string
                    // We can proceed with the substitution of the search terms to highlight them
                    if (toSubstitute === inputValuesArray.length) {
                        // We create the substitute string
                        let subStr = '<strong>$&</strong>';
                        // We execute the replaceAll with the RegExp Object and the substitute string
                        let result = originalStr.replaceAll(regex, subStr);
                        // In order to avoid strange behavior by the browser regarding whitespace and contiguous html tags, we replace normal whitespace by unbreakable ones
                        let newStr = result.replaceAll(' ', '&nbsp;');
                        // Now we clean again the string before implementing it in the html of the historic item
                        let cleanStr = newStr.replaceAll('\\s', ' ');
                        JKisio(historic).html(cleanStr);
                        // In case of previous masked historic items, we show it
                        JKisio(historic).show();
                    } else {
                        // We did NOT found ALL search terms in the historic item string, so we hide this historic item
                        JKisio(historic).hide();
                    }
                }
            }
        } else {
            // In case of previous masked historic items, we show all of them in case of empty inputed value
            JKisio('.historic-list li').show();
        }
        //Now we test if the inputed value made all historic items disappear
        if (JKisio('.historic-list').length === 0) {
            // In this case we need to hide the .historic-list elt
            JKisio('.historic-list').hide();
        }
    }


    // Manage the click on an item in the historic list
    JKisio(document).on('click', '.autocomplete .historic-item', function () {
        let itemHidden = JKisio(this).attr('id');
        // remove element
        JKisio(this).find('.removable-text').remove();

        // find html
        let item = JKisio(this).find('.content span').text();
        if(item === ""  || item == null || typeof item == 'undefined') {
            item = JKisio(this).find('.header span').text();
        }
        let parent = JKisio(this).parents('.autocomplete');
        let fieldtype;
        if (parent.attr('id') == 'search_from') {
            fieldtype = 'from';
        } else if (parent.attr('id') == 'search_to') {
            fieldtype = 'to';
        }

        let object_coord = JKisio(this).attr('data-coord').split(';');
        manageGeoMarkers(fieldtype, '', [object_coord[0], object_coord[1]]);
        JKisio(parent).find('.autocompletable').val(item.trim());
        JKisio(parent).find('.autocompletable-hidden').val(itemHidden);
        JKisio(parent).find('.historic-list').remove();
        JKisio(parent).find('.autocomplete-clear em').show();
        JKisio(parent).find('.locate-position').show();
        JKisio(parent).removeClass('focus-in');
        JKisio('#journey-form-reverse').show();
        JKisio(`#search_${fieldtype}_autocomplete`).val(JKisio(`#search_${fieldtype}_autocomplete`).val()).trigger('change');
        window.dispatchEvent(new CustomEvent("journey_input_address_changed"));
    });

    JKisio(document).on('click', '.locate-position', function (){
        JKisio('.historic-list').hide();
    })

    // Manage the click on the cross in the autocomplete
    JKisio(document).on('click', '.ikisio-clear-field', function (e) {
        // what a mess, this part has to be executed whever or not we have a map
        let id = JKisio(this).attr('id');
        if (!["search-from-autocomplete-clear", "search-to-autocomplete-clear"].includes(id)) {
            return;
        }

        JKisio('#journey-search-board-modes').show();
        JKisio('#journey_content').addClass('open_search');

        executeIfMapExist(() => {
            if (window.leaflet?.markers == undefined) {
                return;
            }

            if (id === "search-from-autocomplete-clear") {
                window.leaflet.markers.from = "";
            }else if (id === "search-to-autocomplete-clear") {
                window.leaflet.markers.to = "";
            }
        });
    })

    // Manage the mouseleave on the input list
    JKisio(document).on('mouseleave', '.autocomplete .historic-list', function () {
        let parent = JKisio(this).parents('.autocomplete');
        let parentId = JKisio(parent).attr('id');
        JKisio(parent).find('.historic-list').remove();
        JKisio(parent).removeClass('focus-in');
        if (parentId === "search_from" || parentId === "search_to" ) {
            const dataType = parentId.replace("search_", "");
            JKisio('#journey-form-reverse').show();
            JKisio('.locate-position').filter(`[data-type="${dataType}"]`).show();
        }
    });

    // Manage the re-click when the focus's on
    JKisio(document).on('click', '#search_from_autocomplete, #search_to_autocomplete', function () {
        const dataType = JKisio(this).parent().attr('id').replace('search_', '');
        JKisio('.locate-position')
            .filter(`[data-type=${dataType}]`)
            .hide();
        showHistoricList(this);
        showBookmarkList(this);
        JKisio(this).focusout(function () {
            JKisio('.locate-position')
                .filter(`[data-type=${dataType}]`)
                .show();
        });
    });

    // Manage the click on an item in the autocomplete list (only for journey)
    JKisio(document).on('click', '.search_from_autocomplete .ui-menu-item, .search_to_autocomplete .ui-menu-item', function (e) {
        window.dispatchEvent(new CustomEvent("journey_input_address_changed")); // why done here ?
        JKisio('.historic-list').remove();
        JKisio('.bookmark-list').remove();
    });

    document.addEventListener('autocomplete_open', () => {
       JKisio('.historic-list').remove();
       JKisio('.bookmark-list').remove();
    });
}

/**
 * Function to add Historic of Journey search to Cookie
 * @param {string} label Valeur du champs texte
 * @param {string} uri Valeur du champs caché
 */
export function addHistoricSearchToCookie(label, uri, coord, itemType, lines) {
    let client = new BookmarkClientFactory().createClient();

    let addressBookmark = client.getOneSync(coord);

    if(addressBookmark !== null) {
        return;
    }
    //Value to insert into cookie
    let value = {
        input: encodeURIComponent(label),
        inputHidden: encodeURIComponent(uri),
        coord: coord, 
        type: itemType,
        lines: lines !== undefined ? lines.map(line => ({
            id: line.id,
            color: line.color,
            code: line.code,
            text_color: line.text_color,
            mode: line.physical_modes[0].id.split(":")[1].toLowerCase(),
            commercial_mode: line.commercial_mode
        })) : undefined
    };

    //Read the cookie
    let historicCookie = read_local_storage_item('historic');
    if (historicCookie !== undefined && historicCookie !== null && historicCookie.length > 0) {
        //Check if the value is already present
        let valueAlreadyPresent = false;
        for (const element of historicCookie) {
            if (element.input !== undefined && element.inputHidden !== undefined && element.input === value.input && element.inputHidden === value.inputHidden) {
                valueAlreadyPresent = true;
            }
        }
        if (valueAlreadyPresent === false) {
            //Insert value into cookie
            historicCookie.push(value);
            //Update its content as soon as there are 3 elements
            if (historicCookie.length > Kisio.historic_limit_value){
                historicCookie.splice(0,1);
            }
            // if Cookie already exist, we need to complete it
            set_local_storage_item('historic', historicCookie);
        }
    } else {
        //cookie doesn't exist, we create it
        let cookieValue = [];
        cookieValue.push(value);
        set_local_storage_item('historic', cookieValue);
    }
};

/**
 * Function used to filter the Historic list based on inputed value
 * @param elt
 */
export function filterHistoricList(elt) {
    // Before filtering, we make sure that the historic entries container is visible
    // in the case of one of those should be "appearing" depending on the filter
    JKisio('.historic-list').show();
    // We get the value inputed, trimmed of trailing whitespaces
    let inputValueString = elt.value.trim(' ');
    if (!(isIE9 || isIE10 || isIE11)) {
        // On all modern browsers, we normalize the string to deconpose any accented characters and then remove the accent
        inputValueString = inputValueString.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    }
    // We filter only if the value is not empty
    if (inputValueString.length > 0) {
        // We build an array of terms to search by differentiating them based on whitespace separation in the inputed value
        let inputValuesArray = inputValueString.split(' ');
        // We check if the split has generated any empty string in the Search Terms array
        for (let i = 0; i < inputValuesArray.length; i++) {
            if (inputValuesArray[i] === '') {
                // When it is the case, we dropped them from the array
                inputValuesArray.splice(i,1);
            }
        }
        // We start building the string used later for the substitution Regex by joining the searh array terms with a | in order to search alternatively all terms
        const inputValuesStrRegex = '('+inputValuesArray.join('|')+')';
        // We build the Regex with Global & Insensitive flags to search for all occurences & despite cases
        let regex = new RegExp(inputValuesStrRegex.replace(/[.()]/g, '\\$&'), 'gi');
        // We get all historic items to cycle through them to decide if we need to hide them or keep them and highlight the search terms
        let historicList = JKisio('.historic-list li');
        Object.keys(historicList).forEach(function(element) {
            // We get the text ONLY form the historic item
            let originalStr = JKisio(element).text();
            if (!(isIE9 || isIE10 || isIE11)) {
                // On all modern browsers, we normalize the string to deconpose any accented characters and then remove the accent
                originalStr = originalStr.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            }
            // We need to determine before making the sustitution, if all terms are found in the item string
            // We initiate a variable which will increase when we found an occurence of a search term in the item string
            let toSubstitute = 0;
            for (const element of inputValuesArray) {
                let reg = new RegExp(element.replace(/[.()]/g, '\\$&'), 'gi');
                if (reg.test(originalStr)) {
                    toSubstitute++;
                }
            }
            // If the variable previously increased is equal to the length of the Search terms array, it means that all terms have been found in the historic item string
            // We can proceed with the substitution of the search terms to highlight them
            if (toSubstitute === inputValuesArray.length) {
                // We create the substitute string
                let subStr = '<strong>$&</strong>';
                // We execute the replaceAll with the RegExp Object and the substitute string
                let result = originalStr.replaceAll(regex, subStr);
                // In order to avoid strange behavior by the browser regarding whitespace and contiguous html tags, we replace normal whitespace by unbreakable ones
                let newStr = result.replaceAll(' ', '&nbsp;');
                // Now we clean again the string before implementing it in the html of the historic item
                let cleanStr = newStr.replaceAll('\\s', ' ');
                JKisio(element).html(cleanStr);
                // In case of previous masked historic items, we show it
                JKisio(element).show();
            } else {
                // We did NOT found ALL search terms in the historic item string, so we hide this historic item
                JKisio(element).hide();
            }
        })
    } else {
        // In case of previous masked historic items, we show all of them in case of empty inputed value
        JKisio('.historic-list li').show();
    }
    //Now we test if the inputed value made all historic items disappear
    if (JKisio('.historic-list li:visible').length === 0) {
        // In this case we need to hide the .historic-list elt
        JKisio('.historic-list').hide();
        JKisio('.bookmark-list').hide();
    }
}
