import {getNextDepartures, showDeparturesLine} from "./utils";

/**
 * Show stop_points by id_line
 *
 * @param id_line
 * @param color
 */
export function showStopPointsByLine(line) {
    if (window.Kisio.show_one_line_by_id) {
        if (typeof line.id === "undefined") {
            return console.log('Error : no id line, sent:' + line.id);
        }

        JKisio.ajax({
            url: Routing.generate('r_public_transport_objects_filtered', {
                type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                public_transport_object_type: 'lines',
                public_transport_object_id: line.id,
                action: 'stop_points',
                count: 100
            }),
            async: true,
            dataType: 'json',
            success: function (data) {
                let stopPoints = data.stop_points;
                if (typeof stopPoints !== "undefined") {
                    let group = L.featureGroup(); //.addTo(window.leaflet.map);
                    Kisio.layerGroup.home.homeLayers.addLayer(group);

                    stopPoints.forEach(function (sp) {
                        if (!sp.coord || !sp.coord.lat || !sp.coord.lon) {
                            return;
                        }

                        const circleMarker = L.circleMarker(
                            [sp.coord.lat, sp.coord.lon], {
                                radius: 5,
                                width: 0.1,
                                color: "#" +line.color,
                                fillColor: "#" + line.color,
                                fillOpacity: 1,
                                sp: sp,
                                layer_section: ['home'],
                                layerType: 'stop_point',
                                line_id: line.id,
                                weight: 10
                            }
                        ).addTo(group);

                        //L.layerGroup(circleMarker).addTo(window.leaflet.map);
                        Kisio.layerGroup.home.homeLayers.addLayer(L.layerGroup(circleMarker));

                        circleMarker
                            .on('mouseover', function (e) {
                                if(window.leaflet.map.getZoom() >= Kisio.map_config.zoom + 1) {
                                    e.target.bindPopup(showDeparturesLine(line, sp), {
                                        autoPan: false
                                    }).openPopup([e.latlng.lat, e.latlng.lng]);
                                }
                            });
                    });
                    Kisio.layerGroup.home.homeLayers.addTo(window.leaflet.map);
                } else {
                    console.log("No data for stop points available");
                }
            },
            error: function (xhr, status, error) {
                console.log(error);
            }
        });
    }
};
