import {
    read_local_storage_item,
    set_local_storage_item,
    delete_all_local_storage_items
} from './localStorage';
import { erase_all_cookies } from './cookies';

// We look in the query of the URL if there is a no_cookie parameter
let queryUserConsent;
let urlParams = window.location.search;
let captured = /user_consent=([^&]+)/.exec(urlParams);
if (captured !== undefined && captured !== null) {
    queryUserConsent = captured[1];
}
if (queryUserConsent === 'false') {
    // If it is set to "false", we set the Kisio user_content parameter to false also, to disable use of cookies and local storage
    Kisio.user_consent = 0;
    // We clear all cookies from our domain and all Local Storage
    erase_all_cookies();
    delete_all_local_storage_items();
    // We keep in Local Storage an only entry specifying that we do not want to track user data via Local Storage or cookies
    set_local_storage_item('user_consent', 'false');
} else if (queryUserConsent === 'true') {
    // We are in the case of a user who agrees to cookies & Local Storage
    // We set an entry in the Local Storage to remember user_consent set to true
    set_local_storage_item('user_consent', 'true');
    // We assure that the Kisio user_consent parameter is set to true to enable use of cookies and local storage
    Kisio.user_consent = 1;
} else {
    // In case we have no parameter "user_consent" in the URL, we still need to verify that we have an entry in Local Storage for user_consent set to true
    // If it is the case, we need to set the Kisio user_consent parameter to true to enable use of cookies and local storage
    let localStorageUserConsent = read_local_storage_item('user_consent');
    if (localStorageUserConsent === 'true') {
        Kisio.user_consent = 1;
    } else if (localStorageUserConsent === 'false') {
        Kisio.user_consent = 0;
    } else {
        // We have no user_consent in the query, we have no user_consent in the Local Storage, se we need to place ourselves in the opt-in situation
        // So we put the JS variable to 0 by default
        Kisio.user_consent = 0;
        // We clear all cookies from our domain and all Local Storage
        erase_all_cookies();
        delete_all_local_storage_items();
    }
}
