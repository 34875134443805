import { read_local_storage_item, set_local_storage_item, delete_local_storage_item } from './localStorage';

export var addFavoriteToCookie, delFavoriteFromCookie;

if (Kisio.enable_fav_cookies) {

    /**
     * Function to add Favorite to Cookie
     * @param typeSectionFavorite
     * @param typeFavorite
     * @param paramsFavorite
     */
    addFavoriteToCookie = function(typeSectionFavorite, typeFavorite, paramsFavorite) {
        let favoritesCookie = read_local_storage_item('favorites');
        let typeSectionFavoriteArray = typeSectionFavorite.split(',');
        if (favoritesCookie === null) {
            // In case of Cookie not already existing, we need to create one
            let cookieValue = {};
            cookieValue[typeFavorite] = {
                section: typeSectionFavoriteArray,
                list: []
            };
            cookieValue[typeFavorite].list.push(paramsFavorite);
            set_local_storage_item('favorites', cookieValue);
        } else {
            // The cookie is already existing, we extract the favorite's type object or create it if it does not exist
            if (favoritesCookie[typeFavorite] === undefined) {
                favoritesCookie[typeFavorite] = {
                    section: typeSectionFavoriteArray,
                    list: []
                };
            }
            // We extract then the favorite's type list of favorites from its Type Object
            let favoritesTypeCookie = favoritesCookie[typeFavorite].list;
            let alreadyPresent = false;
            // We check if this particular favorite is already present
            for (let favoriteTypeCookie of favoritesTypeCookie) {
                if (favoriteTypeCookie === paramsFavorite) {
                    alreadyPresent = true;
                }
            }
            if (!alreadyPresent) {
                // If this particular favorite is not present, we add it to its Type section in the JSON and we re-create the cookie
                favoritesCookie[typeFavorite].list.push(paramsFavorite);
                set_local_storage_item('favorites', favoritesCookie);
            }
        }
    }

    /**
     * Function to delete Favorite from the cookie
     * @param typeFavorite
     * @param paramsFavorite
     */
    delFavoriteFromCookie = function(typeFavorite, paramsFavorite) {
        // We get the List of Favorites from the cookie
        let listFavoritesCookie = read_local_storage_item('favorites');
        if (listFavoritesCookie !== null) {
            // We extract the favorite's type list from the cookies JSON
            let favoritesTypeCookie = listFavoritesCookie[typeFavorite].list;
            let params;
            for (let i = 0; i < favoritesTypeCookie.length; i++) {
                if (typeFavorite === 'journey_solution') {
                    // In the case of journey_solution favorite in Cookies, we need to decode once more its content for proper comparison with the favorite handled by the JS
                    params = decodeURIComponent(favoritesTypeCookie[i]);
                    if (params === paramsFavorite) {
                        // We found the favorite and we delete its value from the list
                        favoritesTypeCookie.splice(i,1);
                    } else {
                        // We need to try once more without decodeng it because encoding vary between PHP and JS
                        params = favoritesTypeCookie[i];
                        if (params === paramsFavorite) {
                            // We found the favorite and we delete its value from the list
                            favoritesTypeCookie.splice(i,1);
                        }
                    }
                } else {
                    params = favoritesTypeCookie[i];
                    if (params === paramsFavorite) {
                        // We found the favorite and we delete its value from the list
                        favoritesTypeCookie.splice(i,1);
                    }
                }
            }
            if (favoritesTypeCookie.length > 0) {
                // We still have some Favorites for this Type
                // We update the JSON with the modified favorite's type list
                listFavoritesCookie[typeFavorite].list = favoritesTypeCookie;
            } else {
                // No Favorites remain for this type, we need to delete the type from the Global Array
                delete listFavoritesCookie[typeFavorite];
            }
            if (Object.keys(listFavoritesCookie).length > 0) {
                // We still have some Favorites of other Types so we re-create the cookie
                set_local_storage_item('favorites', listFavoritesCookie);
            } else {
                // There is no longer any Favorites in the cookie, we need to erase it
                delete_local_storage_item('favorites');
            }
        }
    }
}
