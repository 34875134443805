import { getLinePicto, getModePicto } from "../../../plugnplay/customize_client";
import { manageClickPopUpJourneyButtons } from "../../../plugnplay/leaf-core";
import { BookmarkStarElementBuilder} from "bookmark_bundle/components/bookmark-star/bookmark-star-element.js";
import { NavitiaBuilder } from "bookmark_bundle/builder/navitia/navitia-builder";
import  { ButtonDepartureArrival } from "journey_bundle/modules/buttonDepartureArrival/builder";

/**
 * When clicking on a stop point or stop area, this will show a popup containing all lines passing by
 *
 * @param object
 * @param object_type
 * @returns {HTMLDivElement}
 */
export function showDeparturesLine(line, object) {
    let el = document.createElement('div');
    let coords;
    if (object.coord !== undefined && object.coord.lat !== undefined && object.coord.lon !== undefined) {
        coords = object.coord.lat + ';' + object.coord.lon;
    }

    if (Kisio.filter_by_stop_point !== 1) {
        return el;
    }

    let lat = object.coord.lat, lng = object.coord.lon, popup = L.popup(), latlng = {lat, lng};
    let type_product = (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '';
    JKisio.ajax({
        url: Routing.generate('coords', { coords: lng+';'+lat, type_product: type_product }),
        dataType: "json",
        async: false,
        success: function(result) {
            const address = result.label;
            const latlng = JSON.stringify({lat: lat, lng: lng});

            let buttonPopUp =  new ButtonDepartureArrival({
                lat: lat,
                lng: lng,
                address: address
            }).buildHtml();

            if (type_product === '' || type_product === 'standalone') {
                if (getSectionName() !== 'journey') {
                    buttonPopUp = '';
                }
            }

            const customPopup = document.createElement('div');
            customPopup.id = 'leaflet-popup';
            customPopup.setAttribute('tabindex', 0);

            // create popup header
            customPopup.innerHTML = '<div id="leaflet-popup-header">' + '<div class="popup-info-line"><span class="commercial_mode">' + getModePicto(line.commercial_mode.id) + '</span>' + getLinePicto(line) + object.label +'</div></div>';

            // create popup content
            const popupContent = document.createElement('div');
            popupContent.className = 'clearfix';
            popupContent.id = 'leaflet-popup-content';
            popupContent.innerHTML = buttonPopUp;
            getNextDepartures(
              line,
              object.stop_area ? object.stop_area.id : object.id,
              object.stop_area ? 'stop_areas' : 'stop_points'
            ).then((departuresContainer) => {
                popupContent.appendChild(departuresContainer);
            });
            customPopup.appendChild(popupContent);

            // popup.setLatLng(latlng).setContent(customPopup).openOn(window.leaflet.map);
            el.appendChild(customPopup);
        }
    });

    return el;
};

export async function getNextDepartures(line, stopId, stopType) {
    let nextDeparturesEl = createLoader();
    let departuresHtml = [];

    // get next departures for two routes
    await Promise.all(line.routes.map(async (route, i) => {
        const differences = await fetchDeparturesForRoute(stopId, line.id, route.id, stopType);
        const directionName = line.routes[i].name;

        departuresHtml.push(renderNextDepartures(differences, directionName));
    }));

    nextDeparturesEl.innerHTML = departuresHtml.join('');

    return nextDeparturesEl;
}

async function fetchDeparturesForRoute(stopId, lineId, routeId, stopType) {
    const departures = await getNextDeparturesForStopType(stopId, lineId, routeId, stopType);

    return calculateDifferenceForNextDepartures(departures[0]);
}

function manageFavButton(line, object) {
    let navitiaBuilder = new NavitiaBuilder();
    let type = "stop_area_lines_direction";
    let params = navitiaBuilder.stopAreas().setStopArea(
        object.stop_area.id,
        line.id,
        line.commercial_mode.id,
        line.code,
        line.color,
        line.text_color,
        line.network.id,
        object.stop_area.name,
        object.stop_area.coord.lat,
        object.stop_area.coord.lon
    ).getNavitiaObject();

    const ariaLabelAdd = Translator.trans('line_schedule.result.favorites.stop_line_direction.add');
    const ariaLabelDel = Translator.trans('line_schedule.result.favorites.stop_line_direction.del');

    const builder = new BookmarkStarElementBuilder({
        className: "fav-star fav-star-add fav-stop_area_lines_direction",
        title: ariaLabelAdd,
        type: type,
        ariaLabelAdd: ariaLabelAdd,
        params: params
    });
    return builder.buildHtml();
}

export async function getNextDeparturesForStopType(stopId, lineId, routeId, stopType) {
    let nextDepartures = [];
    await JKisio.ajax({
        url: Routing.generate('journey_departures',
            {
                type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                line_id: lineId,
                stop_area_id: stopId,
                route_id: routeId,
                stop_type: stopType
            }
        ),
        dataType: "json",
        success: function(result) {
            nextDepartures.push(result);
        }});

    return nextDepartures;
}

export function calculateDifferenceForNextDepartures(times) {

    if ("error" in times) {
        return 'error';
    }

    // Get the current date and time
    const currentDate = new Date();
    const currentHours = currentDate.getHours().toString().padStart(2, "0");
    const currentMinutes = currentDate.getMinutes().toString().padStart(2, "0");
    const currentTime = `${currentHours}:${currentMinutes}`;

    // Create an object to store the results
    const results = { times: [], data_freshness: times['data_freshness'] };
    // Loop through each time in the input array
    if (times['times']) {
        for (let i = 0; i < times['times'].length; i++) {
            const time = times['times'][i]; // access the i-th element of the 'times' array
            // Split the current time and the time from the input array into hours and minutes
            const [hour1, minute1] = currentTime.split(':');
            const [hour2, minute2] = time.split(':');

            // Calculate the difference between the current time and the time from the input array in minutes
            const diffInMinutes = (parseInt(hour2) - parseInt(hour1)) * 60 + (parseInt(minute2) - parseInt(minute1));

            // If the difference is less than 60 minutes, push the absolute difference in minutes to the results array
            if (Math.abs(diffInMinutes) < 60) {
                results['times'].push(`${Math.abs(diffInMinutes)}`);
            }
        }
    }
    // Return the results object
    return results;
}

export function renderNextDepartures(differences, route_name = null) {
    let realTimeImage = '';
    let nextDepartures = '...';

    if (differences === "error") {
        return "";
    }

    if (differences['times'].length > 0) {
        // Join the differences array into a string, separated by ", " and adding "mn" at the end
        nextDepartures = differences['times'].map((diff, index) => index === differences['times'].length - 1 ? `${diff}mn` : diff).join(", ");

        // If real-time is activated, set real-time image
        if (differences['data_freshness'] === 'realtime') {
            realTimeImage = '<img src="/bundles/canaltppnpcore/images/realtime.gif" alt="realtime" />';
        }
    }

    // Initialize the routes HTML as an empty string
    let routesHtml = "";

    // If route_name is not null, add the routes HTML
    if (route_name !== null) {
        routesHtml = `
            <div id="routes">
                <em class="ikisio ikisio-arrow-destination"></em>
                ${route_name}
            </div>
        `;
    }

    // Return the span with all the elements combined
    return `
        <div class="departure-container">
            ${routesHtml}
            <div class="times_container">
                ${realTimeImage}
                ${nextDepartures}
            </div>
        </div>
    `;
}

export function manageDisriptions(disruptions) {
    const arrayOfDisruption = [];
    let level_severity = '';
    disruptions.forEach(function (d) {
        let severity = d.severity.effect;
        switch (severity) {
            case 'NO_SERVICE':
                level_severity = 'disrupt';
                break;
            case 'SIGNIFICANT_DELAYS':
            case 'REDUCED_SERVICE':
            case 'DETOUR':
            case 'ADDITIONAL_SERVICE':
            case 'MODIFIED_SERVICE':
            case 'UNKNOWN_EFFECT':
            case 'STOP_MOVED':
                level_severity = 'warning';
                break;
            case 'OTHER_EFFECT':
            default:
                level_severity = 'information';
                break;
        }
        arrayOfDisruption[d.impacted_objects[0].pt_object.id] = {
            'cause' : d.cause,
            'image': '/bundles/canaltppnpcore/images/disruptions/'+level_severity+'.svg'
        }
    });

    return arrayOfDisruption;
}

export function getImgByMode(mode) {
    let img = window.Kisio.modes_img[mode];
    if (typeof window.Kisio.modes_img[mode] === 'undefined') {
        img = window.Kisio.modes_img['commercial_mode:' + mode];
    }
    return img;
};

export function createLoader() {
    const loader = document.createElement('div');
    loader.className = "journey-section__loader";

    loader.innerHTML = '<img src="/bundles/canaltppnpcore/images/picto/16/loading.gif" alt="loader">';

    return loader;
}

function getSectionName() {
    // get selected module from url
    const routeParams = window.location.pathname.split('/');

    return routeParams[3];
}
