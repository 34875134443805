/**
 * Manage error popup for Journey
 * @type {NodeListOf<HTMLElementTagNameMap[string]>}
 */
let forms = document.querySelectorAll( "form" );
for (const form of forms) {
    replaceValidationUI(form);
}

function replaceValidationUI(form){
    form.addEventListener( "invalid", function( event ) {
        event.preventDefault();
    }, true );
    let submitButtons = form.querySelectorAll( "button:not([type=button]), input[type=submit]" );
    if (submitButtons.length) {
        submitButtons.forEach(function (submitButton) {
            submitButton.addEventListener( "click", (event) => {showErrorMessage(event, form)});
            submitButton.addEventListener( "showErrorMessage", (event) => {showErrorMessage(event, form)});
        });
    }
}

function showErrorMessage( event, form ) {
    if (JKisio('.popup.show').length === 0) {
        let element = 0;
        let invalidFields = form.querySelectorAll( ":invalid" );

        let listHtml = "";
        let errorMessages = document.createElement("div");
        let label;
        errorMessages.className = 'popup';

        for(const invalidField of invalidFields) {
            if (invalidField.id) {
                element += 1;
                let parentElement = JKisio(invalidField).parent();
                if (parentElement.attr('id')) {
                    parentElement.addClass('error-message');
                    JKisio(invalidField).focus();
                    label = form.querySelector("#" + invalidField.id );
                    let translation = 'journeys.' + parentElement.attr('id').split(/_/gi)[1] + '.errors.blank';
                    
                    if(invalidField.dataset.errormessage != null) {
                        translation = invalidField.dataset.errormessage;
                        delete invalidField.dataset.errormessage;
                    }
                    
                    listHtml +="<span class='popuptext' id='myPopup' tabindex='0'>" +
                    "<i class='ikisio ikisio-warning'></i>" +
                    label.innerHTML +
                    " " +
                    Translator.trans(translation) +
                    "</span>";
                    
                    parentElement.context.addEventListener('click', function() {
                        JKisio('#myPopup').remove();
                    });
                }
            }

            let marginTop = "-5px";

            if(invalidField.dataset.margintop != null) {
                marginTop = invalidField.dataset.margintop;
            }


            if (element === 1 && label){
                JKisio(label).height();
                errorMessages.innerHTML = listHtml;
                JKisio(errorMessages).insertBefore('#' + label.id);
                errorMessages.classList.toggle('show');
                JKisio('#myPopup').position({
                    of: JKisio('#' + label.id ),
                    my: "center bottom",
                    at: "center top"
                });
                JKisio('#myPopup').css({'margin-top': marginTop});
            }
        }
        JKisio('#myPopup').focus();
    }
}

/**
 * Manage error popup for Next departures
 */
JKisio('#schedule_stop_area_lines_autocomplete').on('keydown', function (event) {
    if (!JKisio('.schedule_stop_area_lines_autocomplete').is(':visible')) {
        if (event.keyCode === 13 && !JKisio('schedule_stop_area_lines_autocomplete-hidden').val() && JKisio('#error_popup').length === 0) {
            event.preventDefault();
            let html ="<div class='popup show'><div id='error_popup'><span class='popuptext" + (Kisio.locale === 'en' ? " popuptext-en" : "" ) + "' id='myPopup' tabindex='0'>" +
                "<i class='ikisio ikisio-warning' aria-hidden='true'></i>" +
                "<span aria-hidden='true'>" + Translator.trans('journeys.next_departures.errors.blank') + "</span>" +
                "<span class='sr-only' aria-live='assertive'>" + Translator.trans('journeys.next_departures.errors.blankAria') + "</span>" +
                "</span></div></div>";
            JKisio(html).insertBefore('#schedule_stop_area_lines_autocomplete');
            JKisio('#myPopup').focus();
        }
    }
});
JKisio(document).on('input', '#schedule_stop_area_lines_autocomplete', function () {
    JKisio('#schedule_stop_area_lines .popup').remove();
});